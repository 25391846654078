// Bootstrap Link
import "././node_modules/bootstrap/dist/css/bootstrap.min.css"
import "././node_modules/bootstrap/dist/js/bootstrap.bundle"
// Style Sheets
import './src/styles/style.css';
import './src/styles/style.scss';
import './src/styles/responsive.scss';

export const onRouteUpdate = ({ location, prevLocation }) => {
    window.scrollTo(0, 0);
};